body {
  width: 100vw;
  height: 100vh;
  background-color: black;
  margin: 0;
  padding: 0;
}

.wall {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
}

.wall-item {
  flex: 1 0 33.3333%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wall-item > .video {
  width: 100%;
}
